// Show the loading dialog
export function showLoadingDialog() {
    const model = document.createElement('div');
    model.className = 'modal fade';
    model.id = "loadingModal";
    model.tabIndex = "-1";
    model.dataset.bsBackdrop = "static";
    model.ariaHidden = "true";
    model.role = "dialog";
    
    const loaderModelHTML = `
      <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div class="row m-5">
              <div class="d-flex justify-content-center align-items-center flex-column">
                <div class="spinner-border spinner-border-lg text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
                <p class="text-center mt-3 mb-0">Please wait...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
    
    model.innerHTML = loaderModelHTML;
    document.body.appendChild(model);
    
    var loadingModal = new bootstrap.Modal(model, {
      backdrop: 'static',
      keyboard: false
    });
    loadingModal.show();
}

// Hide the loading dialog
export function hideLoadingDialog() {
  setTimeout(()=>{
    $('#loadingModal').modal('hide');
    $('#loadingModal').remove();
  }, 500);
}


  
export function alertModel(modelId, reqStatus, btnObject, title, description) {
  const model = document.createElement('div');
  model.className = 'modal fade';
  model.id = modelId;
  model.tabIndex = "-1";
  model.dataset.bsBackdrop = "static";
  model.ariaHidden = "true";
  model.role = "dialog";

  const loaderModelHTML = `
    <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
      <div class="modal-content modal-dialog-centered">
        <div class="modal-body">
          <div class="row">
            <div class="d-flex justify-content-center align-items-center flex-column">
              <div class="lottie-container"></div>
              <h5 class="text-center mt-2">${title}</h5>
              <p class="text-center mb-0">${description}</p>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  `;
  
  model.innerHTML = loaderModelHTML;
  document.body.appendChild(model);
  
  const footer = model.querySelector('.modal-footer');
  btnObject.forEach(btn => {
    const button = document.createElement('a');
    button.href = btn.href;
    button.textContent = btn.text;
    button.className = 'btn';
    if (btn.text.toLowerCase().includes('close')) {
      button.id = "close";
      button.onclick = function() {
        console.log("Close btn click");
        hideModel(modelId);
      };
      button.classList.add('btn-outline-secondary');
    } else {
      button.classList.add('btn-primary');
    }
    footer.appendChild(button);
  });

  let lottieFiles = "https://panel.hopehomecalcutta.org/storage/lottiefiles/error.json";
  if(reqStatus == 200){
    lottieFiles = "https://panel.hopehomecalcutta.org/storage/lottiefiles/done.json";
  } else if(reqStatus == 300){
    lottieFiles = "https://panel.hopehomecalcutta.org/storage/lottiefiles/cross.json";
  }

  // Load Lottie animation after modal is appended to the document
  setTimeout(() => {
    lottie.loadAnimation({
      container: model.querySelector('.lottie-container'), // the DOM element to contain the animation
      renderer: 'svg', // render as SVG
      loop: true, // loop the animation
      autoplay: true, // start playing the animation immediately
      path: lottieFiles // the path to the animation JSON file
    });
  }, 100); // Small delay to ensure the DOM is fully updated

  var loadingModal = new bootstrap.Modal(model, {
    backdrop: 'static',
    keyboard: false
  });

  loadingModal.show();
}


export function hideModel(modelId) {
  $(`#${modelId}`).modal('hide');
  $(`#${modelId}`).remove();
}
  
export function alertPromptModel(modelId, btnText1, btnText2, title, description) {
    return new Promise((resolve) => {
      const model = document.createElement('div');
      model.className = 'modal fade';
      model.id = modelId;
      model.tabIndex = "-1";
      model.dataset.bsBackdrop = "static";
      model.ariaHidden = "true";
      model.role = "dialog";
  
      const loaderModelHTML = `
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content modal-dialog-centered">
            <div class="modal-body">
              <div class="row">
                <div class="d-flex justify-content-center align-items-center flex-column">
                  <h5 class="text-center mt-2">${title}</h5>
                  <p class="text-center mb-0">${description}</p>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" id="no" class="btn btn-outline-secondary" onclick=>${btnText2}</button>
              <button type="button" id="yes" class="btn btn-primary">${btnText1}</button>
            </div>
          </div>
        </div>
      `;
  
      model.innerHTML = loaderModelHTML;
      document.body.appendChild(model);
  
      const loadingModal = new bootstrap.Modal(model, {
        backdrop: 'static',
        keyboard: false
      });

      loadingModal.show();
      setTimeout(()=>{
        document.getElementById("yes").addEventListener("click", function () {
          $(`#${modelId}`).modal('hide');
          $(`#${modelId}`).remove();
          resolve(true);
        });
    
        document.getElementById("no").addEventListener("click", function () {
          $(`#${modelId}`).modal('hide');
          $(`#${modelId}`).remove();
          resolve(false);
        });
      }, 100);
    });
}
  
export function createAndShowToast(toastClass, title, body, time) {
    const toastId = 'toast' + Math.random().toString(36).substr(2, 9);
    const toastDiv = document.createElement('div');
    toastDiv.className = `bs-toast toast toast-placement-ex m-2 fade top-50 start-50 translate-middle ${toastClass}`;
    toastDiv.id = toastId;
    toastDiv.dataset.bsDelay = "5000";
    toastDiv.role = "alert";
    toastDiv.ariaLive = "assertive";
    toastDiv.ariaAtomic = "true";
  
    const toastHtml = `
      <div class="toast-header">
        <i class="bx bx-bell me-2"></i>
        <div class="me-auto fw-semibold text-capitalize">${title}</div>
        <small>${time !== undefined ? showTheCurrentTimer(time) : "Just now"}</small>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
      <div class="toast-body">${body}</div>
    `;
  
    toastDiv.innerHTML = toastHtml;
    document.body.appendChild(toastDiv);
  
    const bootstrapToast = new bootstrap.Toast(toastDiv);
    bootstrapToast.show();
}
  
// Show the downloading model
export function createDownloadingProgressModel() {
    const model = document.createElement('div');
    model.className = 'modal fade';
    model.id = "uploadFilesModel";
    model.tabIndex = "-1";
    model.dataset.bsBackdrop = "static";
    model.ariaHidden = "true";
    model.role = "dialog";
    
    const loaderModelHTML = `
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="uploadFilesModelTitle">Uploading files</h5>
          </div>
          <div id="uploadContainer" class="download-model-container modal-body">   
            
          </div>
          <div class="modal-footer">
            <button id="cancel-uploading" type="button" class="btn btn-outline-warning" data-bs-dismiss="modal">
              Cancel Uploading
            </button>
            <button id="uploading-done" type="button" class="btn btn-primary" data-bs-dismiss="modal">Done</button>
          </div>
        </div>
      </div>
    `;
    
    model.innerHTML = loaderModelHTML;
    document.body.appendChild(model);
    
    var downloadingModal = new bootstrap.Modal(model, {
      backdrop: 'static',
      keyboard: false
    });
    downloadingModal.show();
    return true;
}